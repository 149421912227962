<template>
  <div class="container-fluid">
    <Header header="Detail" title="Notification" :state="state"/>

    <div class="row">
      <!-- Column -->
      <div class="col-12">
          <table>
              <thead>
                  <tr><th>Detail</th></tr>
              </thead>
              <tbody>
                  <tr>
                      <td>Judul</td>
                      <td>:</td>
                      <td>{{ current.title }}</td>
                  </tr>
                  <tr>
                      <td>Pesan</td>
                      <td>:</td>
                      <td>{{ current.message }}</td>
                  </tr>
                  <tr>
                      <td>Waktu Notitifikasi Diterima&nbsp;&nbsp;&nbsp;</td>
                      <td>:</td>
                      <td>{{ formatDateTime(current.created_at) }}</td>
                  </tr>
              </tbody>
          </table>
      </div>
      <!-- Column -->
    </div>

    <!-- END DASHBOARD -->
  </div>
</template>
<script>
import axios from "axios";
import Header from "@/components/pageTitle/index";

export default {
  components: {
    Header
  },
  data() {
    return {
        state: {
            isEdit: false,
            isAdd: false,
            isDetail: false,
        },
        current:{
            title:'',
            message:'',
            created_at:new Date()
        }
    };
  },
  computed: {
    headerNotif() {
      return this.$store.state.headerNotif;
    }
  },
  mounted() {
      axios.get(`/notification-detail/${this.$route.params.id}`).then(res=>{
          this.current = res.data.data;
      }).catch(()=>{
      });
  }
};
</script>
